<template>
  <footer>
    <p>이 페이지는 상업적인 목적이 없는 포트폴리오 페이지입니다.</p>
    <p>©2023 장예지. All rights reserved.</p>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/components/footer.scss';
</style>
